<template>
	<section class="container mt-4">
		<div class="row mb-3">
			<div class="col text-center" v-if="$can('create', 'Company')">
				<router-link to="/company/add">
					<b-button variant="primary" class="mb-3"> <i class="fas fa-plus-circle"></i> {{ $t('companyList.create') }} </b-button>
				</router-link>
			</div>
		</div>
		<div class="row" v-if="isAdvisorOrAdmin">
			<div class="col-md-12">
				<b-card no-body class="mb-2">
					<b-card-header>
						<b> <InputCheck v-model="filterAdvisor" :text="'Rådgiver filtrering'" /> </b>
					</b-card-header>
					<b-card-body>
						<div class="row">
							<div class="col-md-4 mb-3">
								<InputDropdown v-model="selectedUser" :options="advisorsAndAdmins" placeholder="Rådgiver" :disabled="!filterAdvisor" />
							</div>
							<div class="col-md-2 mb-3">
								<InputCheck v-model="primaryAdvisor" :text="'Primære rådgiver'" :disabled="!filterAdvisor" />
							</div>
							<div class="col-md-4 mb-3">
								<InputCheck v-model="secondaryAdvisor" :text="'Sekundær rådgiver'" :disabled="!filterAdvisor" />
							</div>
							<div class="col-md-4 mb-3">
								<InputCheck v-model="showInactiveCompanies" :text="'Vis kun opsagte virksomheder'" />
							</div>
						</div>
					</b-card-body>
				</b-card>
			</div>
		</div>
		<div class="row">
			<div class="col-12 mb-3">
				<b-form-input class="input col-12" v-model="globalfilter" placeholder="Søg" />
			</div>
			<div class="col-12">
				<BaseTable :items="companies" @currentPageChanged="currentPageChanged" @perPageChanged="perPageChanged" :fields="fields" :isBusy="isBusy">
					<template v-slot:cell(index)="data">
						{{ currentPage * perPage - perPage + data.index + 1 }}
					</template>
					<template v-slot:cell(companyInfo.name)="data">
						<router-link :to="{ name: 'Company', params: { companyId: data.item.id } }">
							{{ data.item.companyInfo.name }}
						</router-link>
					</template>
					<template v-slot:cell(employees)="row">
						<b-button size="sm" @click="row.toggleDetails" class="mr-2">
							<i class="fas fa-users"></i> {{ row.item.employees.length }} Medarbejder
						</b-button>
					</template>
					<template #row-details="{ item }">
						<div
							class="col-auto mx-auto"
							v-for="(employeeId, index) in item.employees"
							:key="`employee-${employeeId}`"
							:aria-label="`Employee ${index + 1} details`"
						>
							{{ index + 1 }}. {{ getUser(employeeId) }}
						</div>
					</template>
				</BaseTable>
			</div>
		</div>
	</section>
</template>
<script>
import BaseTable from '@/modules/global/components/BaseTable'
import i18n from '@/plugins/i18n'
import Swal from 'sweetalert2'
import clientVersionMixin from '@/mixins/clientVersionMixin'
import InputDropdown from '@/modules/global/atomComponents/InputDropdown.vue'
import InputCheck from '@/modules/global/atomComponents/InputCheck.vue'

export default {
	name: 'CompanyList',
	components: {
		BaseTable,
		InputDropdown,
		InputCheck,
	},
	mixins: [clientVersionMixin],
	created() {
		this.selectedUser = this.$store.getters['auth/uid']
	},
	watch: {
		filterAdvisor: {
			handler: 'setCookie',
			immediate: false,
		},
	},
	data() {
		return {
			currentPage: 1,
			perPage: 30,
			globalfilter: '',
			filterAdvisor: true,
			selectedUser: null,
			primaryAdvisor: true,
			secondaryAdvisor: false,
			showInactiveCompanies: false,
			fields: [
				{
					key: 'index',
					label: '',
				},
				{
					key: 'companyInfo.name',
					label: i18n.t('companyList.table.name'),
					sortable: true,
				},
				{
					key: 'companyInfo.cvr',
					label: i18n.t('companyList.table.cvr'),
					sortable: true,
				},
				{
					key: 'employees',
					label: i18n.t('companyList.table.employees'),
					sortable: true,
				},
			],
		}
	},
	async mounted() {
		this.$emit('updateLoader')
		await this.$store.dispatch('company/getCompanies') // Load data fra api+
		this.checkClientVersion()
		this.loadCookie()
	},
	methods: {
		loadCookie() {
			this.filterAdvisor = this.$cookies.get('showCompanyList') == 'true'
		},
		currentPageChanged(page) {
			this.currentPage = page
		},
		perPageChanged(perPage) {
			this.perPage = perPage
		},
		onDelete(id) {
			Swal.fire({
				title: 'Slet virksomhed?',
				text: 'Vil du slette denne virksomhed? Du kan ikke fortryde dette.',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ja overskriv!',
				showLoaderOnConfirm: true,
				preConfirm: async () => {
					try {
						await this.$store.dispatch('companyVuex/deleteCompany', id)
					} catch (error) {
						Swal.showValidationMessage(`Request failed: ${error}`)
					}
				},
			}).then(async (result) => {
				if (result.isConfirmed) {
					Swal.fire('Slettet!', 'Virksomheden er nu slettet', 'success')
				}
			})
		},
		getUser(employeeId) {
			var user = this.users.find((user) => user.id === employeeId)
			return user?.fullName
		},
		setCookie() {
			console.log('setting cookie')
			this.$cookies.set('showCompanyList', this.filterAdvisor)
		},
	},
	computed: {
		companies() {
			var companies = this.$store.getters['company/companies']
			companies = companies.sort((a, b) => (a.companyInfo.name > b.companyInfo.name ? 1 : -1))
			return companies
				.filter((company) => {
					return company.isActive == !this.showInactiveCompanies
				})
				.filter((obj) => {
					if (this.globalfilter == '') {
						return true
					} else {
						return Object.keys(obj).some((key) => {
							try {
								var filtered = JSON.stringify(obj[key]).toLowerCase()
								return filtered.includes(this.globalfilter.toLowerCase())
							} catch (err) {
								console.log(key)
							}
						})
					}
				})
				.filter((obj) => {
					if (this.isAdvisorOrAdmin) {
						if (!this.filterAdvisor) {
							return true
						}
						if (this.selectedUser == null) {
							return true
						} else {
							return (
								(this.primaryAdvisor && obj.companyAdvisors.primaryAdvisor == this.selectedUser) ||
								(this.secondaryAdvisor && obj.companyAdvisors.secondaryAdvisor == this.selectedUser)
							)
						}
					} else {
						return true
					}
				})
				.map((company) => {
					var isResignedCompany = company.companyInfo.endDate != null
					let rowVariant = isResignedCompany ? 'danger' : ''
					return {
						...company,
						_rowVariant: rowVariant,
					}
				})
		},
		users() {
			return this.$store.getters['user/users']
		},
		advisorsAndAdmins() {
			return this.users
				.filter((user) => user.role.isAdmin || user.role.isAdvisor)
				.map((user) => {
					return {
						value: user?.id,
						text: user?.fullName,
					}
				})
				.sort((a, b) => (a.text > b.text ? 1 : -1))
		},
		isBusy() {
			return !this.$store.getters['company/isReady']
		},
		isAdvisorOrAdmin() {
			return this.$store.getters['auth/isAdmin'] || this.$store.getters['auth/isAdvisor']
		},
	},
}
</script>

<style scoped>
.container {
	max-width: 1200px; /* Adjust based on preference */
	margin: auto;
	padding: 20px;
}

.row {
	margin-bottom: 20px;
}

/* Typography */
body,
html {
	font-family: 'Segoe UI', Arial, sans-serif;
}

b,
strong {
	font-weight: 600;
}

/* Buttons and Inputs */
.b-button,
.input {
	border-radius: 5px;
	border: 1px solid #ccc;
}

.b-button {
	background-color: #0056b3;
	color: white;
	padding: 10px 15px;
}

.b-button:hover {
	background-color: #004494;
}

.input {
	padding: 10px;
	width: 100%;
}

/* Color Scheme */
:root {
	--primary-color: #0056b3;
	--secondary-color: #e9ecef;
	--text-color: #333;
}

/* Table Styling */
.BaseTable {
	width: 100%;
	border-collapse: collapse;
}

.BaseTable th,
.BaseTable td {
	text-align: left;
	padding: 8px;
	border-bottom: 1px solid #ddd;
}

.BaseTable th {
	background-color: var(--secondary-color);
}

/* Responsive Design */
@media (max-width: 768px) {
	.col-auto,
	.col-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
}
</style>

const clientVersionMixin = {
	methods: {
		checkClientVersion() {
			console.log('Client version: ' + this.$store.getters['app/appVersion'])
			// Check if the cookie in browser have the same version as the client
			// If the version is different, show a popup which tell the user the client is updated

			// Get the version from the cookie
			var version = this.$cookies.get('version') || null
			var clientVersion = this.$store.getters['app/appVersion']
			var isAdminOrAdvisor = this.$store.getters['auth/isAdmin'] || this.$store.getters['auth/isAdvisor']
			if (version != clientVersion) {
				if (isAdminOrAdvisor) {
					this.$swal.fire({
						title: 'Kundeportalen er blevet opdateret!',
						text: 'Du kan se ændringer ved at trykke på versionnummeret i bunden af siden',
						confirmButtonText: 'OK',
					})
					this.$cookies.set('version', clientVersion, '3y')
				}
			}
		},
	},
}

export default clientVersionMixin
